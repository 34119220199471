<template>
  <button
    :class="`button-i5__wrapper ${setClass(type)}`"
    :style="setStyle()"
    @click="onHandleClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="block__flex block__center block__align-center">
      <div style="margin-right: 7px" v-if="showIcon" :class="setIconClass(type)">
        <slot name="icon"></slot>
      </div>
      <div :style="setTextStyle()">{{ text.toUpperCase() }}</div>
    </div>
  </button>
</template>

<script>
export default {
  name: "I5Button",
  props: {
    text: {
      type: String,
      default: "",
    },
    borderColor: {
      type: String,
      default: "#C9C9C9",
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "#C9C9C9",
    },
    fontSize: {
      type: String,
      default: "20px",
    },
    minHeight: {
      type: String,
      default: "40px",
    },
    textClass: {
      type: Object,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "primary", "dashed", "filter"].indexOf(value) !== -1;
      },
    },
  },
  watch: {},
  data() {
    return {
      hovered: false,
      componentHeight: 38,
      componentWidth: 226,
      dataTextColor: null,
      bodyColor: null,
      dataBorderColor: null,
      strokeDasharray: "7.08 7.08",
    };
  },
  methods: {
    setTextStyle() {
      const result = {
        fontSize: this.fontSize,
      };
      return result;
    },
    onMouseEnter() {
      if (!this.active) {
        this.dataBorderColor = this.$store.state.index.primaryColor;
        this.dataTextColor = this.$store.state.index.primaryColor;
      }
      this.hovered = true;
    },
    onMouseLeave() {
      if (!this.active) {
        this.dataBorderColor = this.borderColor;
        this.dataTextColor = this.textColor;
      }
      this.hovered = false;
    },
    onHandleClick() {
      this.$emit("click");
    },
    setActive(val) {
      if (val) {
        this.bodyColor = this.$store.state.index.primaryColor;
        this.strokeDasharray = "0";
        this.dataTextColor = "white";
        this.dataBorderColor = this.$store.state.index.primaryColor;
      } else {
        this.bodyColor = "white";
        this.strokeDasharray = "7.08 7.08";
        this.dataTextColor = this.textColor;
        this.dataBorderColor = this.borderColor;
      }
    },
    onMouseUp() {
      if (!this.isSwitch) {
        setTimeout(() => {
          this.bodyColor = "white";
          this.strokeDasharray = "7.08 7.08";
          this.dataTextColor = this.textColor;
          this.dataBorderColor = this.borderColor;
        }, 200);
      }
    },
    onMouseDown() {
      if (!this.isSwitch) {
        this.bodyColor = this.$store.state.index.primaryColor;
        this.strokeDasharray = "0";
        this.dataTextColor = "white";
        this.dataBorderColor = this.$store.state.index.primaryColor;
      }
    },
    setStyle() {
      const result = {};
      if (this.minHeight) {
        result.minHeight = this.minHeight;
      }
      return result;
    },
    setClass(type) {
      let result = "";
      if (type === "default") {
        result += "button-i5__default";
        if (this.active) {
          result += " button-i5__default_active";
        }
      } else if (type === "primary") {
        result += "button-i5__primary";
        if (this.active) {
          result += " button-i5__primary_active";
        }
      } else if (type === "dashed") {
        result += "button-i5__dashed";
        if (this.active) {
          result += " button-i5__dashed_active";
        }
      } else if (type === "filter") {
        result += "button-i5__filter";
        if (this.active) {
          result += " button-i5__filter_active";
        }
      } else {
        result += "button-i5__default";
        if (this.active) {
          result += " button-i5__default_active";
        }
      }
      return result;
    },
    setIconClass(type) {
      let result = "";
      if (type === "default") {
        result += "button-i5__icon_default";
        if (this.hovered) {
          result += " button-i5__icon_default_hover";
        }
      } else if (type === "primary") {
        result += "button-i5__icon_primary";
        if (this.hovered) {
          result += " button-i5__icon_primary_hover";
        }
      } else if (type === "dashed") {
        result += "button-i5__icon_dashed";
        if (this.hovered) {
          result += " button-i5__icon_dashed_hover";
        }
      } else {
        result += "button-i5__icon_default";
        if (this.hovered) {
          result += " button-i5__icon_default_hover";
        }
      }
      return result;
    },
  },
  mounted() {
    this.bodyColor = "white";
    this.dataTextColor = this.textColor;
    this.dataBorderColor = this.borderColor;
    this.$el.addEventListener("pointerdown", this.onMouseDown, false);
    this.$el.addEventListener("pointerup", this.onMouseUp, false);
    this.$store.watch(
      (state) => state.index.width,
      () => {
        this.componentHeight = this.$el.clientHeight;
        this.componentWidth = this.$el.clientWidth;
      },
    );
    this.componentHeight = this.$el.clientHeight;
    this.componentWidth = this.$el.clientWidth;
  },
  destroyed() {
    this.$el.removeEventListener("pointerdown", this.onMouseDown, false);
    this.$el.removeEventListener("pointerup", this.onMouseUp, false);
  },
  components: {},
};
</script>
